.spinning {
    margin-right: 7px;
    top: 2px;
    animation: spin 5s infinite linear;
}

@keyframes spin {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(0.25);
    }
}

.heartbeat {
    animation: heartpulse 2s infinite;
}

@keyframes heartpulse {
    0% {
      transform: scale( .95);
    }
    20% {
      transform: scale( 1);
    }
    40% {
      transform: scale( .95);
    }
    60% {
      transform: scale( 1);
    }
    80% {
      transform: scale( .95);
    }
    100% {
      transform: scale( .95);
    }
  }