
.linkNoFontSize {
    color: #aa7f61;
    background-color: transparent;
    font-family: 'DM Sans', sans-serif;;
    border-radius: 99px;
    letter-spacing: 0.05px;
    transition: color 150ms ease-in-out;
}

.footer {
    max-width: 1024px;
}